.footNavWrap {
    width: 100%;
    max-width: 500px;
    position: fixed;
    z-index: 999;
    bottom: 0;
    left: 50%;
    height: calc( 68px + (env(safe-area-inset-bottom)/2));
    transform: translateX(-50%);
    background-color: var(--moment-bg-balck);
    .footNav {
        height: 100%;
        padding: 0 20px;
        padding-top: 5px;
        .footNavLeft{
            width: calc(50% - 30px);
            height: 100%;
            display: flex;
            align-items: start;
            justify-content: space-between;
            //background-color: rgba(255,0,0,0.5);
        }
        .footNavRight{
            width: calc(50% - 30px);
            height: 100%;
            display: flex;
            align-items: start;
            justify-content: space-between;
            //background-color: rgba(0,255,0,0.5);
        }
        .footNavCenter{
            width: 60px;
            height: 100%;
            position: relative;
            .showUploadBtn{
                position: absolute;
                //background-color: var(--TP-yellow);
                width: 60px;
                aspect-ratio: 1;
                //border-radius: 30px;
                top: -18px;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                /*.btnCenter{
                    width: 20px;
                    aspect-ratio: 1;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                }*/
            }
            .createPanel{
                position: absolute;
                width: 137px;
                height: 63px;
                border-radius: 8px;
                border: 1px solid rgba(153,153,153,1);
                background-color: rgba(0,0,0,1);
                transform: translate(-50%, -100%);
                left: 50%;
                top: 20px;
                padding: 0px 6px;
                visibility: hidden;
                .createPanelItem{
                    width: 100%;
                    margin: 8px 0px;
                    .upload{
                        font-size: 12px;
                        font-weight: 400;
                        color: #EEEEEE;
                    }
                }
                .createPanelLine{
                    width: 100%;
                    height: 1px;
                    border-top: 1px solid rgba(153,153,153,1);
                }
            }
        }
        .item {
            flex: 1;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            position: relative;
            .iconWrap{
                height: 40px;
                display: flex;
                align-items: center;
                &.hasAfter{
                    &::after{
                        content: "";
                        position: absolute;
                        width: 10px;
                        height: 10px;
                        top: -20px;
                        inset-inline: 0;
                        margin: auto;
                        border-radius: 0 0 2px 0;
                        transform: translateY(50%) rotate(-135deg);
                        box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.05);
                        z-index: 0;
                        background-color: var(--white);
                    }
                }
            }
            .label{
                width: 100%;
                padding: 0px 0 0px;
                font-size: 12px;
                color: var(--TP-moment-gray);
            }
            &.active{
                .label{
                    color: var(--white);
                }
            }
            .memeAssetRedPoint{
                position: absolute;
                padding: 0px 6px;
                left: calc(50% + 15px);
                top: 10px;
                min-width: 24px;
                height: 18px;
                border-radius: 9px;
                background-color: var(--TP-yellow);
                transform: translate(-50%, -50%);
                .memeAssetRedPointText{
                    font-size: 10px;
                    color: #333333;
                }
            }
        }
    }
}
