
.loginWrap{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
    background-color: var(--black);
    &.pc{
        position: fixed;
        z-index: 999;
        left: 0;
        align-items: flex-end;
        .header{
            min-height: 114px;
            padding: 0 30px;
            background-color: rgba(255,255,255,0.5);
            width: 100%;
            z-index: 1;
            .headerStartBtn{
                width: 242px;
                height: 71px;
                background-color: #4EADFF;
                border-radius: 18px;
                margin-left: 40px;
                font-size: 50px;
                font-weight: 700;
                cursor: pointer;
            }
        }
    }
    .bg{
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        object-fit: cover;
    }
    &.other{
        .header{
            min-height: 57px;
            padding: 0 18px;
            background-color: rgba(255,255,255,0.5);
            width: 100%;
            z-index: 1;
            .headerStartBtn{
                width: 242px;
                height: 71px;
                background-color: #4EADFF;
                border-radius: 18px;
                margin-left: 40px;
                font-size: 50px;
                font-weight: 700;
                cursor: pointer;
            }
        }
        .midStartBtnRoot{
            position: absolute;
            bottom: 0px;
            width: 100%;
            height: 300px;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            .midStartBtn{
                width: 280px;
                height: 80px;
                border-radius: 18px;
                font-size: 50px;
                font-weight: 700;
                background-color: var(--TP-yellow);
                color: rgba(0,0,0,1);
                margin-bottom: 42px;
                cursor: pointer;
            }
        }
    }
}

.loadingWrap{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    background-color: var(--black);
    .logo{
        width: 160px;
        height: 160px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 8px;
    }
    .text{
        margin-top: 40px;
        font-size: 30px;
        color: #EEEEEE;
        font-weight: bold;
    }
}
