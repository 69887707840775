
.momentWrap{
    width: 100%;
    background-color: #4DADFF;
    border-radius: 16px;
    position: relative;
    padding-bottom: 75px;
    .title{
        margin-top: 15px;
        width: 100%;
        height: 45px;
        font-size: 30px;
        color: #FFFFFF;
        text-align: center;
        font-weight: 700;
    }
    .imageRoot{
        margin-top: 15px;
        .image{
            width: 390px;
        }
    }
    .tips{
        margin-top: 15px;
        width: 100%;
        font-size: 14px;
        color: #EEEEEE;
        text-align: center;
        font-weight: 400;
    }
    .commonBtn{
        position: absolute;
        width: 330px;
        height: 90px;
        background-color: rgba(0,0,0,1);
        border-radius: 12px;
        bottom: -30px;
    }
}
  